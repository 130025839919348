body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-area {
  margin-top: 10px;
}

.data-display {
  margin-top: 20px;
  border: 0.5px #232323 solid;
  border-radius: 8px;
  border-top: #007b4f 10px solid;
  max-height: 500px;
  overflow: scroll;
}

.data-display-m {
  margin-top: 20px;
  padding: 5px;
  border: 0.5px #232323 solid;
  border-radius: 8px;
  border-top: #007b4f 10px solid;
  height: 800px;
  overflow: scroll;
}

.data-list {
  margin-top: 10px;
}

hr {
  border: #232323 solid 0.5px !important;
}

/* Login */

.login {
  margin-top: 120px !important;
  max-width: 400px !important;
  height: 320px;
  border: 0.5px solid #e6e6e6;
  border-radius: 15px;
  padding: 20px;
  background-color: #f0f0f0;
}

/* Spinner */

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #88a925;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
